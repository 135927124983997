<template>
  <div class="container  login-screen">
    <div class="row justify-content-center mx-1 w-100">
      <div class="form-contain col-12 col-md-8 col-lg-6 px-md-3">
        <div class="form-header mb-0" :class="t_ar"> {{ $t("signUp") }} </div>
        <div class="form-subheader mt-0" :class="t_ar"> {{ $t("fillInFields") }} </div>
        <form class="mt-lg-5 mt-3" @submit.prevent="registerUser">
          <div class="row">
            <!-- <div class="form-group col-12">
                  <label class="input-label" :class="f_ar">
                    {{$t('FullName')}}
                  </label>
                  <input  
                    type="text"
                    v-model='fullName'
                    class="form-control text-input" 
                    placeholder="Username"
                    :class="t_ar"  
                    required
                  >
                </div> -->
          </div>
          <div class="row justify-content-center">
            <div class="form-group col-12 px-0">
              <label class="input-label required" :class="f_ar"> {{ isEmail ? $t("col_email") : $t("col_phone") }}
              </label>
              <input v-if="isEmail" :type="isEmail ? 'email' : 'text'" v-model="identifier"
                class="form-control text-input" :placeholder="isEmail ? $t('col_email') : $t('col_phone')" :class="t_ar"
                required />
              <input v-else :type="isEmail ? 'email' : 'text'" v-model="identifier" class="form-control text-input"
                :placeholder="isEmail ? $t('col_email') : $t('col_phone')" :class="t_ar" required id="phone"
                v-mask="'49###############'" :masked="true" @blur="validatePhone" />
            </div>
            <small v-if="!isEmail && (col.error || col.vaild)" :class="[col.valid ? 'text-success' : 'text-error']"
              class="my-2"> {{ col.valid || col.error }} </small>
          </div>
          <div class="row">
            <div class="form-group col-12 px-0">
              <label class="input-label required" :class="[f_ar]"> {{ $t("password") }} </label>
              <input type="password" v-model="password" class="form-control text-input" :placeholder='$t("password")'
                :class="[t_ar, password.length > 5 && password.length < 26 ? 'is-valid' : 'is-invalid']"
                @input="passwordMatch" required :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" />
            </div>
          </div>
          <div class="row">
            <div class="form-group col-12 px-0">
              <label class="input-label required" :class="f_ar"> {{ $t("confirmPassword") }} </label>
              <input type="password" v-model="confirmPassword" class="form-control text-input"
                :placeholder='$t("confirmPassword")' :class="[t_ar, isMatch ? 'is-valid' : 'is-invalid']"
                @input="passwordMatch" required :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" />
            </div>
            <!-- <div class="col-12 px-0 text-center" v-if="password && confirmPassword && !match">
              <small id="passwordHelp" class="text-danger"> {{ $t("passwordMustMatch") }} </small>
            </div> -->
          </div>
          <div class="text-center">
            <button :disabled="loading || !isMatch" :class="loading || !isMatch ? 'btn-secondary' : ''" type="submit"
              class="btn-pri-lg my-lg-4 mb-2 mt-2 signUp-btn"> {{ $t("signUp") }} </button>
          </div>
          <div class="row text-center">
            <div class="col-lg-12 px-0">
              <div class="form-subheader mt-2"> {{ $t("AlreadyHaveAccount") }} <a
                  class="form-subheader strong text-underline pointer" @click="goLogin">{{ $t("logIn") }}</a>
                <span class="mx-3">{{ $t("OR") }}</span> {{ $t("RegisterOtherMethod") }} <a
                  class="form-subheader strong text-underline pointer" @click="changeRegisterMethod">{{ isEmail ?
                      $t("col_phone") : $t("col_email")
                  }}</a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  data() {
    return {
      registerMethod: "email",
      identifier: "",
      fullName: "",
      password: "",
      confirmPassword: "",
      match: false,
      col: {},
    };
  },
  computed: {
    ...mapGetters(["loading"]),
    isMatch() {
      return this.match
    },
    ar() {
      return this.$i18n.locale == "ar";
    },
    t_ar() {
      return this.$i18n.locale == "ar" ? "text-right" : "text-left";
    },
    flex_ar() {
      return this.$i18n.locale == "ar" ? "flex-row-reverse" : "";
    },
    f_ar() {
      return this.$i18n.locale == "ar" ? "f-right" : "f-left";
    },
    f_ar_reverse() {
      return this.$i18n.locale == "ar" ? "f-left" : "f-right";
    },
    isEmail() {
      return this.registerMethod == "email";
    },
  },
  methods: {
    ...mapActions([
      "userRegisterPhone",
      "userRegisterEmail",
      "updateProfile",
      "checkPhone",
    ]),
    ...mapMutations(["showMsg"]),
    goLogin() {
      this.$router.push({
        name: "Login",
      });
    },
    changeRegisterMethod() {
      this.registerMethod == "email"
        ? (this.registerMethod = "phone")
        : (this.registerMethod = "email");
    },
    validatePhone() {

      if (!empty(this.identifier)) {
        this.$forceUpdate();
        this.checkPhone(this.identifier)
          .then((res) => {
            if (res.data.data == true) {
              this.col.valid = this.$t("VaildNumber");
              this.$forceUpdate();
            } else {
              $("#phone").select();
              this.col.error = this.$t("notVaildNumber");
              this.col.valid = null;
              this.$forceUpdate();
            }
          })
          .catch((err) => {
            $("#phone").select();
            this.col.error = this.$t("notVaildNumber");
            this.col.valid = null;
            this.$forceUpdate();
          });
      } else this.$forceUpdate();
    },
    registerUser() {
      if (this.isEmail == true) {
        this.userRegisterEmail(this.identifier)
          .then((res) => {
            let data = res.data.data;
            this.updateProfile({
              api_token: data.api_token,
              password: this.password,
            })
              .then((res) => {
                this.showMsg({
                  type: "success",
                  text: this.$t("verifyRedirect"),
                  close: false,
                });
                setTimeout(() => {
                  this.$router.push({
                    name: "VerifyAccount",
                    params: {
                      isEmail: this.isEmail,
                      identifier: this.identifier,
                    },
                  });
                }, 2000);
              })
              .catch((err) => {
                console.log(
                  "🚀 ~ file: Register.vue ~ line 217 ~ registerUser ~ err",
                  err
                );
                this.showMsg({
                  type: "error",
                  text: $t("updatePasswordError"),
                  close: false,
                });
              });
          })
          .catch((err) => {
            this.showMsg({
              type: "error",
              text: this.$t("registerError"),
              close: false,
            });
          });
      } else {
        this.userRegisterPhone({
          phone: this.identifier,
          phone_code: "+49",
        })
          .then((res) => {
            let data = res.data.data;
            this.updateProfile({
              api_token: data.api_token,
              password: this.password,
            })
              .then((res) => {
                this.showMsg({
                  type: "success",
                  text: this.$t("verifyRedirect"),
                  close: false,
                });
                setTimeout(() => {
                  this.$router.push({
                    name: "VerifyAccount",
                    params: {
                      isEmail: this.isEmail,
                      identifier: this.identifier,
                    },
                  });
                }, 2000);
              })
              .catch((err) => {
                console.log(
                  "🚀 ~ file: Register.vue ~ line 217 ~ registerUser ~ err",
                  err
                );
                this.showMsg({
                  type: "error",
                  text: $t("updatePasswordError"),
                  close: false,
                });
              });
          })
          .catch((err) => {
            this.showMsg({
              type: "error",
              text: $t("registerError"),
              close: false,
            });
          });
      }
    },
    passwordMatch() {
      if (this.password.length > 5 && this.confirmPassword.length > 5 && this.password.length < 26 && this.confirmPassword.length < 26) {
        if (this.password == this.confirmPassword) {
          this.match = true;
          this.$forceUpdate()
          // console.log("🚀 ~ file: Register.vue ~ line 191 ~ passwordMatch ~ .passwordMatch", this.passwordMatch)
        } else {
          this.match = false;
          // console.log("🚀 ~ file: Register.vue ~ line 191 ~ passwordMatch ~ .passwordMatch", this.passwordMatch)
        }
      } else {
        this.match = false;
      }
    },
  },
};
</script>